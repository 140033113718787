import { Accordion } from "react-bootstrap";

export default function DynamicAccordion({ data }) {
  return (
    <Accordion>
      {data.map((pf, i) => (
        <Accordion.Item eventKey={i} key={i}>
          <Accordion.Header as={"h3"}>{pf.title}</Accordion.Header>
          <Accordion.Body>
            {pf.p.map((pi, k) => (
              <p key={k}>{pi}</p>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
