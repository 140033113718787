import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade, Lazy } from "swiper";
import SlideButton from "./Slide-Button";
import { Button } from "react-bootstrap";
import TycModal from "./TycModal";
import TycGenerator from "./TycGenerator";
import banners from "../../JSON/data.json";
import myStore from "../../mobX/Store";
import { observer } from "mobx-react";
import { Arrowrightw } from "../../icons";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";

const MySwiper = observer(({ segmented_banners }) => {
  const [imgs, setImgs] = useState([]);
  const [swiper, setSwiper] = useState(null);
  const [currentTycType, setCurrentTycType] = useState(null);
  const [currentBannerName, setCurrentBannerName] = useState("");
  const [showTycModal, setShowTycModal] = useState(false);
  const [regisButtonText, setRegisButtonText] = useState("Regístrate");
  const [regis, setRegis] = useState(myStore.regis);
  console.log(imgs)
  const getWidth = useCallback(() => {
    if (myStore.segmented) return myStore.flag ? "300" : "400";
    else return myStore.flag ? "320" : "400";
  }, [myStore.segmented, myStore.flag]);

  const getHeight = useCallback(() => {
    if (myStore.segmented) return myStore.flag ? "410" : "102";
    else return myStore.flag ? "440" : "140";
  }, [myStore.segmented, myStore.flag]);

  const dateInBetween = (banner) => {
    return (
        new Date() >= new Date(banner.startDate) &&
        new Date() <= new Date(banner.endDate)
    );
  };

  const BannerFilter = (banner) => {
    if (!banner.scheduled) return banner;
    return dateInBetween(banner) ? banner : null;
  };

  useEffect(() => {
    if (myStore.flag !== null) {
      const device = myStore.flag ? "mobile" : "desktop";
      const key = `${device}_slide_list`;
      const tempList = (segmented_banners || banners)[key].filter(BannerFilter);
      setImgs(tempList);
    }
  }, [myStore.flag, segmented_banners]);

  useEffect(() => {
    if (swiper) {
      showTycModal ? swiper.autoplay.pause() : swiper.autoplay.run();
    }
  }, [showTycModal, swiper]);

  const indexHandler = (swiper) => {
    setSwiper(swiper);
    if (imgs.length === 0) return;
    const currentImg = imgs[swiper.realIndex];
    setCurrentTycType(currentImg.tycType || "");
    setCurrentBannerName(currentImg.name || "");
    setRegisButtonText(currentImg.ctaText || (myStore.segmented ? "Depositar" : "Regístrate"));
    setRegis(currentImg.ctaURL || (myStore.segmented ? myStore.acceder : myStore.regis));
  };

  const tycContent = useMemo(() => <TycGenerator type={currentTycType} />, [currentTycType]);

  return (
      <div id="carousel-section">
        <Swiper
            onSlideChange={indexHandler}
            modules={[Pagination, EffectFade, Autoplay, Lazy]}
            pagination={{ clickable: true }}
            effect={"fade"}
            lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
            autoplay={{ delay: 32501, disableOnInteraction: true }}
            loop={true}
        >
          {imgs.map((item, index) => (
              <SwiperSlide key={index}>
                <a href={regis}>
                  <picture>
                    <source srcSet={item.image.replace('.jpg', '.avif')} type="image/avif" />
                    <source srcSet={item.image.replace('.jpg', '.webp')} type="image/webp" />
                    <img
                        width={getWidth()}
                        height={getHeight()}
                        src={item.image}
                        alt={item.img_alt}
                        {...(item.lazyload ? { loading: "lazy" } : {})}
                    />
                  </picture>
                </a>
                {!myStore.flag && (
                    <div id="des-reg" className={myStore.segmented ? "segmented-des-reg" : ""}>
                      <Button
                          className={`central-regis gl-effect ${myStore.segmented ? "segmentedReactSwipeButton" : ""}`}
                          href={regis}
                          rel={"nofollow"}
                      >
                        {regisButtonText}
                        <Arrowrightw />
                      </Button>
                      {item.tycType && (
                          <span className="tyc" onClick={() => setShowTycModal(true)}>
                    Términos y condiciones
                  </span>
                      )}
                    </div>
                )}
                {myStore.flag && item.tycType && (
                    <div className="mob-tyc">
                <span className="tyc" onClick={() => setShowTycModal(true)}>
                  Términos y condiciones
                </span>
                    </div>
                )}
              </SwiperSlide>
          ))}
        </Swiper>
        {myStore.flag && <SlideButton regis={regis} ctaText={myStore.segmented ? "Aceptar" : regisButtonText} segmented={myStore.segmented} />}
        <TycModal show={showTycModal} onHide={() => setShowTycModal(false)} title={currentBannerName} content={tycContent} />
      </div>
  );
});

export default MySwiper;
