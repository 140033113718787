import React from "react";
import { Table } from "react-bootstrap";
import terms from "../../JSON/tyc.json";

const TycGenerator = ({ type }) => {
	const term = terms.find((t) => t.type === type);
	return (
		<ol style={{ maxHeight: "50vh", overflowY: "scroll" }}>
			{term.ol.map((li, k) => (
				<li key={k}>
					{li.bolded && <strong>{li.bolded}</strong>}
					{li.text}
					{li.inner && (
						<ul>
							{li.inner.map((li2, k2) => (
								<li key={k2}>{li2}</li>
							))}
						</ul>
					)}
					{li.table && (
						<Table bordered className="mt-2">
							<thead>
							<tr>
								{li.table.th.map((h, k) => (
									<th key={k}>{h}</th>
								))}
							</tr>
							</thead>
							<tbody>
							{li.table.tr.map((tr, i) => (
								<tr key={i}>
									{tr.map((td, j) => (
										<td key={j}>
											{Array.isArray(td) ? (
												<ul>
													{td.map((tds, k) => (
														<li key={k}>{tds}</li>
													))}
												</ul>
											) : (
												td
											)}
										</td>
									))}
								</tr>
							))}
							</tbody>
						</Table>
					)}
				</li>
			))}
		</ol>
	);
};

export default TycGenerator;
