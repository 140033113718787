import React from "react";
import { Modal, Button } from "react-bootstrap";

const TycModal = ({ show, onHide, title, content }) => (
	<Modal centered show={show} onHide={onHide} contentClassName="terms-tyc">
		<Modal.Header closeButton>
			<Modal.Title className="text-center">
				<h5>Términos y condiciones</h5>
				<h5>{title}</h5>
			</Modal.Title>
		</Modal.Header>
		<Modal.Body>{content}</Modal.Body>
		<Modal.Footer>
			<Button onClick={onHide} className="accept-btn">
				Aceptar
			</Button>
		</Modal.Footer>
	</Modal>
);

export default TycModal;
