import React from 'react';
import { Col, NavLink, Image } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';



const LiveGamesDisplay = ({ live_games, device }) => {
	// console.log(device.flag)
	const game_prefix = 'https://m.codere.com.co/deportesCol/#/casino/CasinoPage?lobby=Colombia&playgame=';
	const baseUrl = 'https://www.codere.com.co/lobby_tiles/';
	const imgSuffix = '_Square.jpg';
	const playHoverIcon = 'https://www.codere.com.co/Colombia/images/casinoIcons/playHoverLogo.svg';




	return (
		<div className="row casino-row">
			{live_games.slice(0, !isMobile ? live_games.length : 4).map((game, index) => {
				const imgUrl = `${baseUrl}${game.name.replace(/\s+/g, '')}${imgSuffix}`;
				return (
					<Col lg={2} md={4} xs={6} key={index}>
						<NavLink rel="nofollow" href={`${game_prefix}${game.name}`}>
							<div className="cas-game-wrapper">
								<Image
									src={imgUrl}
									alt={game.name}

									fluid
									className={'rounded-10'}
								/>
								<div className="hvr">
									<p>{game.name}</p>
									<Image src={playHoverIcon} alt="Play" />
								</div>
							</div>
						</NavLink>
					</Col>
				);
			})}
		</div>
	);
};

export default LiveGamesDisplay;
